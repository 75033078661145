<template>
  <div>
    <!-- Our_Teachers Start -->
    <section id="Our_Teachers_page">
      <div class="container">
        <!-- Title -->
        <div class="row Our_Teachers_title justify-content-center">
          <div class="col-12 text-center">
            <h1>Bizning <span>O'qituvchilarimiz</span></h1>
            <p>Standartlarga ega professionallar</p>
          </div>
        </div>
        <!-- Cardlar -->
        <div class="row justify-content-center card_team_in">
          <!-- Card 1 -->
          <div class="col-12 col-md-6 col-lg-4 cards_item">
            <div class="cardd">
              <div class="imgBx">
                <img src="/img/Teachers_2.png" alt="Teachers_2"/>
              </div>
              <div class="content">
                <h2>Agzamova Nargiz Mirvalievna</h2>
                <p>
                  Direktor
                </p>
              </div>
            </div>
          </div>
           <!-- Card 2 -->
           <div class="col-12 col-md-6 col-lg-4 cards_item">
            <div class="cardd">
              <div class="imgBx">
                <img src="/img/Teachers_1.png" alt="Teachers_1" />
              </div>
              <div class="content">
                <h2>Daminova Saule Turliboevna</h2>
                <p>
                  O'quv-Ma'rifiy ishlar bo'yicha direktor O'rinbosari.
                </p>
              </div>
            </div>
          </div>
          <!-- Card 3 -->
          <div class="col-12 col-md-6 col-lg-4 cards_item">
            <div class="cardd">
              <div class="imgBx">
                <img src="/img/Teachers_3.png" alt="Teachers_3" />
              </div>
              <div class="content">
                <h2>Fyodorova Аlyona Ivanovna</h2>
                <p>
                  Fortepiano O'qituvchisi
                </p>
              </div>
            </div>
          </div>
          <div class="all_card_item">
            <div class="d_none_card row justify-content-center">
              <!-- 4 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_4.png" alt="Teachers_4" />
                  </div>
                  <div class="content">
                    <h2>Card Four</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 5 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_5.png" alt="Teachers_5" />
                  </div>
                  <div class="content">
                    <h2>Card Five</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 6 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_7.png" alt="teachers_7"/>
                  </div>
                  <div class="content">
                    <h2>Card Seven</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 7 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_6.png" alt="teachers_6"/>
                  </div>
                  <div class="content">
                    <h2>Card Eight</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 8 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_8.png" alt="teachers_8"/>
                  </div>
                  <div class="content">
                    <h2>Card Eight</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 9 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_10.png" alt="teachers_10"/>
                  </div>
                  <div class="content">
                    <h2>Unknown</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 10 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_11.png" alt="teachers_11"/>
                  </div>
                  <div class="content">
                    <h2>Unknown</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 11 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_12.png" alt="teachers_12"/>
                  </div>
                  <div class="content">
                    <h2>Unknown</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
              <!-- 12 -->
              <div class="col-12 col-md-6 col-lg-4 cards_item">
                <div class="cardd">
                  <div class="imgBx">
                    <img src="/img/Teachers_13.png" alt="teachers_13"/>
                  </div>
                  <div class="content">
                    <h2>Unknown</h2>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Molestiae, cum iusto. Voluptates fugiat magni, neque
                      necessitatibus voluptatibus rem vero impedit. Dolor quae
                      ape
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button class="close_batafsil d-block mx-auto mt-4">Yopish</button>
          </div>
        </div>
        <!-- 2talik rasm -->
        <div class="row justify-content-center">
          <div class="col-12 col-sm-10 pb-5">
            <img src="/img/Teachers_Team_1.png" alt="Teachers_Team_1" class="img-fluid rounded">
          </div>
          <div class="col-12 col-sm-10 pb-5">
            <img src="/img/Teachers_Team_2.png" alt="Teachers_Team_2" class="img-fluid rounded">
          </div>
        </div>
        <div class="team_btn">
          <router-link to="/TeacherTeam" class="team_link">Batafsil</router-link>
        </div>
      </div>
    </section>
    <!-- Our_Teachers End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style >

</style>
