<template>
  <div>
    <!-- Our_Teachers Start -->
    <section id="Our_Teachers_page">
      <div class="container">
        <!-- Title -->
        <div class="row Our_Teachers_title justify-content-center">
          <div class="col-12 text-center">
            <h1>Bizning <span>O'qituvchilarimiz</span></h1>
            <p>Standartlarga ega professionallar</p>
          </div>
        </div>
        <!-- Cardlar -->
        <div class="row justify-content-center card_team_in">
          <!-- Card 1 -->
          <div class="col-12 col-md-6 col-lg-4 cards_item">
            <div class="cardd">
              <div class="imgBx">
                <img src="/img/Teachers_2.png" alt="Teachers_2" />
              </div>
              <div class="content">
                <h2>Agzamova Nargiz Mirvalievna</h2>
                <p>
                  Direktor
                </p>
              </div>
            </div>
          </div>
          <!-- Card 2 -->
          <div class="col-12 col-md-6 col-lg-4 cards_item">
            <div class="cardd">
              <div class="imgBx">
                <img src="/img/Teachers_1.png" alt="Teachers_1" />
              </div>
              <div class="content">
                <h2>Daminova Saule Turliboevna</h2>
                <p>
                  O'quv-Ma'rifiy ishlar bo'yicha direktor O'rinbosari.
                </p>
              </div>
            </div>
          </div>
          <!-- Card 3 -->
          <div class="col-12 col-md-6 col-lg-4 cards_item">
            <div class="cardd">
              <div class="imgBx">
                <img src="/img/Teachers_3.png" alt="Teachers_3" />
              </div>
              <div class="content">
                <h2>Fyodorova Аlyona Ivanovna</h2>
                <p>
                  Fortepiano O'qituvchisi
                </p>
              </div>
            </div>
          </div>
          <div class="d_none_card row justify-content-center">
            <!-- 4 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_4.png" alt="Teachers_4" />
                </div>
                <div class="content">
                  <h2>Shamsutdinova Yelena Genadevna</h2>
                  <p>
                    Fortepiano O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 5 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_5.png" alt="Teachers_5" />
                </div>
                <div class="content">
                  <h2>Usmonova Nigora Rajabboevna</h2>
                  <p>
                    Xalq cholg'ulari bo'limi dutor O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 6 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_7.png" alt="teachers_7" />
                </div>
                <div class="content">
                  <h2>Usmonova Sanobar Choriyevna</h2>
                  <p>
                    Xalq cholg'ulari bo'limi rubob O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 9 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_9.png" alt="teachers_9" />
                </div>
                <div class="content">
                  <h2>Xoliqov Zafarjon Rasuljon o'g'li</h2>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
            <!-- 10 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_10.png" alt="teachers_10" />
                </div>
                <div class="content">
                  <h2>Bakumenko Narixo</h2>
                  <p>
                    Аkademik xonandalik (vokal) O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 11 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_11.png" alt="teachers_11" />
                </div>
                <div class="content">
                  <h2>Normurodov Botirjon Baxtiyor o'g'li</h2>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
            <!-- 12 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_12.png" alt="teachers_12" />
                </div>
                <div class="content">
                  <h2>Bozorova Nuriya Musurmonqulovna</h2>
                  <p>
                    Fortepiano O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- teacher_card2 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/teacher_card2.jpg" alt="teacher_card2" />
                </div>
                <div class="content">
                  <h2>Sorokoletova Nina Fyodorovna</h2>
                  <p>
                    Fortepiano O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- teacher_card3 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/teacher_card3.jpg" alt="teacher_card3" />
                </div>
                <div class="content">
                  <h2>Narzullaeva Marjona Karimovna</h2>
                  <p>
                    Fortepiano O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 14 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_15.png" alt="Teachers_15" />
                </div>
                <div class="content">
                  <h2>Choriev Sardor Charshanbievich</h2>
                  <p>
                    Jo'rnavoz
                  </p>
                </div>
              </div>
            </div>
            <!-- 16 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_16.png" alt="Teachers_16" />
                </div>
                <div class="content">
                  <h2>Nosirova Go'zal Saidjonova</h2>
                  <p>
                    Jo'rnavoz
                  </p>
                </div>
              </div>
            </div>
            <!-- 17 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_17.png" alt="Teachers_17" />
                </div>
                <div class="content">
                  <h2>JDovliev Sherxon Farxodovich</h2>
                  <p>
                    Rubob O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 18 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_18.png" alt="Teachers_18" />
                </div>
                <div class="content">
                  <h2>Djuraeva Ra'no Radjapovna</h2>
                  <p>
                    Tasviriy San'at O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 19 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_19.png" alt="Teachers_19" />
                </div>
                <div class="content">
                  <h2>Pardaev Muxriddin Djabbarovich</h2>
                  <p>
                    Doira O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 20 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_20.png" alt="Teachers_20" />
                </div>
                <div class="content">
                  <h2>Xolova Svetlana Petrovna</h2>
                  <p>
                    Musiqa nazariyasi O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
            <!-- 21 -->
            <div class="col-12 col-md-6 col-lg-4 cards_item">
              <div class="cardd">
                <div class="imgBx">
                  <img src="/img/Teachers_21.png" alt="Teachers_21" />
                </div>
                <div class="content">
                  <h2>Аlimov Sharif Zarifovich</h2>
                  <p>
                    Skripka O'qituvchisi
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 2talik rasm -->
          <div class="row justify-content-center">
            <div class="col-12 col-sm-10 pb-5">
              <img
                src="/img/Teachers_Team_1.png"
                alt="Teachers_Team_1"
                class="img-fluid"
              />
            </div>
            <div class="col-12 col-sm-10 pb-5">
              <img
                src="/img/Teachers_Team_2.png"
                alt="Teachers_Team_2"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="card_team_in">
            <router-link to="/" class="close_batafsil">Asosiy sahifa</router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- Our_Teachers End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style scoped>
#Our_Teachers_page {
  padding-top: 150px;
}
</style>
