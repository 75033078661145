<template>
    <div>
        <!-- Ready-Training Start -->
        <section id="Ready_Training_page">
            <div class="container">
                <div class="row Ready_Training_title justify-content-center">
                    <div class="col-12 text-center">
                        <h1>Treningni boshlashga <span>tayyormisiz?</span></h1>
                        <p>Dasturingizni tanlang va hoziroq mashg'ulotni boshlang...</p>
                    </div>
                </div>
                <div class="row Read_form justify-content-center">
                    <div class="col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7">
                        <form>
                        <div class="Form_inp">
                            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Ism va familiya yozing..." required minlength="5" maxlength="25">
                        </div>
                        <div class="Form_inp my-4">
                            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" required>
                        </div>
                        <div class=" Form_text">
                            <div class="form-floating">
                            <textarea class="form-control" placeholder="Izoh yozing..." id="floatingTextarea"></textarea>
                            </div>
                        </div>
                        <button type="submit" class="btn form_btn mx-auto d-block">Yuborish</button>
                        </form>
                    </div>
                </div>
                <div class="row py-5 justify-content-center Read_contat">
                    <div class="col-12 text-center">
                        <a target="_blank" href="http://www.facebook.com/groups/termez2bmsm/">
                        <i class="bi bi-facebook"></i>
                        </a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCt9apTp77SQtx6lOYWAB4Kg">
                        <i class="bi bi-youtube"></i>
                        </a>
                        <a target="_blank" href="https://www.instagram.com/bmsmtermiz2/">
                        <i class="bi bi-instagram"></i>
                        </a>
                        <a target="_blank" href="https://t.me/Termez_2BMSM">
                        <i class="bi bi-telegram"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- ReadyTrainingPage End -->
    </div>
</template>

<!-- Script -->
<script>
export default {
    
}
</script>

<!-- Style -->
<style></style>