<template>
    <div>
            <!-- question-answer Start -->
            <div id="Question_answer">
                <div class="container">
                    <div class="row Gallery_title justify-content-center">
                        <div class="col-12 text-center">
                            <h1>Tez-tez so'raladigan <span>savollarni o'qing!</span></h1>
                            <p>Bu yerda siz eng ko'p beriladigan savollarga javob topasiz...</p>
                        </div>
                    </div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <!-- 1 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Here is the answer to the smart question
                            </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic officiis harum quia placeat iure ratione neque excepturi, eius maiores voluptatum eos voluptate provident amet delectus quidem doloremque molestiae vel sed magni soluta minima.
                            </div>
                            </div>
                        </div>
                        <!-- 2 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Here is the answer to another great question
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptate sit dolorem aliquid aperiam ipsam aut quaerat, culpa, laborum, suscipit maxime iure nostrum ducimus.
                            </div>
                            </div>
                        </div>
                        <!-- 3 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Some stupid question goes here...
                            </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi dignissimos delectus quaerat corporis quae nostrum, explicabo facere inventore! Ratione nobis quasi vitae nemo consectetur, perferendis doloremque, quo, modi ipsum dolores quibusdam laboriosam amet.
                            </div>
                            </div>
                        </div>
                        <!-- 4 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFoor">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFoor" aria-expanded="false" aria-controls="flush-collapseFoor">
                                Some stupid question goes here...4
                            </button>
                            </h2>
                            <div id="flush-collapseFoor" class="accordion-collapse collapse" aria-labelledby="flush-headingFoor" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint doloremque quibusdam corporis exercitationem autem facere!
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-5 justify-content-center">
                    <div class="col-12 pt-2 text-center">
                        <p class="text-white">Agar o'zingizga kerakli ma'lumotni topmasangiz - <a href="#Ready_Training_page" class="text-warning">Biz bilan bog'laning </a>va biz sizga yordam beramiz!</p>
                    </div>
                    </div>
                </div>
            </div>
            <!-- question-answer End -->
    </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style></style>