<template>
  <div>
    <!-- Our-Students Start -->
    <section id="Our_Students">
      <div class="container">
        <div class="row Our_Student_title justify-content-center">
          <div class="col-12 text-center">
            <h1>Bizning <span>O'quvchilarimiz</span></h1>
            <p>Bizning eng ilg'or o'quvchilarimiz</p>
          </div>
        </div>
        <div class="row justify-content-center Student_card">
          <!-- 1 -->
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 py-3 py-sm-0">
            <div class="cards">
              <div class="imgBix">
                <img src="/img/Student_img_13.png" alt="Student-img-13">
              </div>
              <div class="content_text">
                <div class="details">
                  <h3>Unknown <br> <span>Tasviriy san'at o'quvchisi</span></h3>
                </div>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 py-3 py-sm-0">
            <div class="cards">
              <div class="imgBix">
                <img src="/img/Student-img-11.png" alt="Student-img-11">
              </div>
              <div class="content_text">
                <div class="details">
                  <h3>Nomalum <br> <span>Fortepiano o'quvchisi</span></h3>
                </div>
              </div>
            </div>
          </div>
          <!-- 3 -->
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 py-3 py-sm-0">
            <div class="cards">
              <div class="imgBix">
                <img src="/img/Student_img_12.png" alt="Student_img_12">
              </div>
              <div class="content_text">
                <div class="details">
                  <h3>неизвестный <br> <span>Dutor o'quvchisi</span></h3>
                </div>
              </div>
            </div>
          </div>
          <!-- 4 -->
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 py-3 py-sm-0">
            <div class="cards">
              <div class="imgBix">
                <img src="/img/Student-img-13.png" alt="Student-img-13">
              </div>
              <div class="content_text">
                <div class="details">
                  <h3>Unknown <br> <span>Fortepiano o'quvchisi</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Our-Students End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->

<style></style>
