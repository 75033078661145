<template>
  <div>
    <!-- Footer Start -->
    <footer class="Footer">
      <div class="container">
        <div class="row Footer_contact justify-content-center">
          <div class="col-12 text-center">
            <a href="tel:+998946028097">+998 94 602 80 97</a>
            <a href="#bmstermiz2@gmail.com">
              <p>bmstermiz2@gmail.com</p>
            </a>
            <a href="https://goo.gl/maps/mi4Veb41kSvPzoM89" target="_blank">
              Termiz shahar,
            </a>
            <a href="#" class="Footer_logo">
              <h3>T2B<span>MSM</span></h3>
            </a>
            <a href="#Navbarr" class="Footer_Up">
              <i class="bi bi-chevron-up"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style></style>
