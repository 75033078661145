<template>
  <div>
    <!-- Navbar Start -->
    <nav id="Navbarr" class="navbar navbar-expand-sm">
      <div class="container">
        <!-- ------------- -->
        <button
          class="navbar-toggler mx-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler border-0 p-0">
            <i class="bi bi-justify-left fs-3"></i>
          </span>
        </button>
        <!-- ----------------- -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <a class="navbar-brand d-none d-md-block" href="#">
            T2B<span>MSM</span>
          </a>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 py-4">
            <li class="nav-item">
              <routerLink to="/" class="nav-link">Asosiy qism</routerLink>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Our_Services_page">Xizmatlar</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Courses_Trainings_page">Kurslar</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Our_Teachers_page">O'qituvchilar</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Question_answer">FAQ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Testimonials_page">Izohlar</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Ready_Training_page">Bog'lanish</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->
  </div>
</template>

<!-- Script -->
<script>
export default {
    
}
</script>

<!-- Style -->
<style>
</style>
