<template>
  <div>
    <!-- Home start -->
    <Home />
    <!-- Home End -->
    <!-- AboutDjBeats Start -->
    <AboutDjBeats />
    <!-- AboutDjBeats End -->
    <!-- OurServicesPage Start -->
    <OurServicesPage />
    <!-- OurServicesPage End -->
    <!-- CoursesTrainingsPage Start -->
    <CoursesTrainingsPage />
    <!-- CoursesTrainingsPage End -->
    <!-- OurAchievementsPage Start -->
    <OurAchievementsPage />
    <!-- OurAchievementsPage End -->
    <!-- OurTeachersPage Start -->
    <OurTeachersPage />
    <!-- OurTeachersPage End -->
    <!-- Our_Students Start -->
    <OurStudents />
    <!-- Our_Students End -->
    <!-- TestimonialsPage Start -->
    <!-- <TestimonialsPage /> -->
    <!-- TestimonialsPage End -->
    <!-- Photo_Gallery Start -->
    <PhotoGallery />
    <!-- Photo_Gallery End -->
    <!-- Video_Gallery Start -->
    <VideoGallery />
    <!-- Video_Gallery End -->
    <!-- QuestionAnswer Start -->
    <!-- <QuestionAnswer /> -->
    <!-- QuestionAnswer End -->
    <!-- ReadyTrainingPage Start -->
    <ReadyTrainingPage />
    <!-- ReadyTrainingPage End -->
  </div>
</template>

<!-- Script -->
<script>
import Home from "../components/Home";
import AboutDjBeats from "../components/AboutDjBeats";
import OurServicesPage from "../components/OurServicesPage";
import CoursesTrainingsPage from "../components/CoursesTrainingsPage";
import OurAchievementsPage from "../components/OurAchievementsPage";
import OurTeachersPage from "../components/OurTeachersPage";
import OurStudents from "../components/OurStudents";
import TestimonialsPage from "../components/TestimonialsPage";
import PhotoGallery from "../components/PhotoGallery";
import QuestionAnswer from "../components/QuestionAnswer";
import ReadyTrainingPage from "../components/ReadyTrainingPage";
import VideoGallery from "../components/VideoGallery";
export default {
  components: {
    Home,
    AboutDjBeats,
    OurServicesPage,
    CoursesTrainingsPage,
    OurAchievementsPage,
    OurTeachersPage,
    OurStudents,
    TestimonialsPage,
    PhotoGallery,
    QuestionAnswer,
    ReadyTrainingPage,
    VideoGallery,
  },
};
</script>

<!-- Style -->
<style></style>
