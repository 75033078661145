<template>
  <div>
    <!-- Header Start -->
    <header id="Header">
      <div class="full_cover"></div>
      <div class="container">
        <div class="row header_title">
          <div class="col-11 col-md-9 col-lg-6">
            <p class="navbar-brand d-block d-md-none">T2B<span>MSM</span></p>
            <h1 class="d-inline-block">
              Surxondaryo viloyati Termiz shahar
              <span>2-son Bolalar Musiqa va San'at Maktabi</span>
            </h1>
            <!-- <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.dolore magna aliquam erat volutpat.aliquam erat.</p> -->
            <button type="button" class="btn">Darsni boshlash</button>
            <div class="row header_title_2">
              <div class="col-11 col-md-9 col-lg-6">
                <h5><a href="tel:+998946028097">+998 94 602 80 97</a></h5>
                <h5><a href="#">bmstermiz2@gmail.com</a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style scoped>
#Header{
  position: relative;
}
.full_cover{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
