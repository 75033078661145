<template>
  <div>
    <!-- About_djBeats Start -->
    <section id="About_djBeats">
      <div class="container">
        <div class="row About_title">
          <div class="col-12">
            <h1><span>Biz</span> Haqimizda</h1>
            <p>Biz haqimizda yanada ko'proq bilib oling</p>
          </div>
        </div>
        <!-- About_title End -->
        <div class="row justify-content-center About_content py-2">
          <div class="col-12 col-xl-6">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/B916xng_Gpo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-12 col-md-10 col-xl-6 pt-5 pt-xl-0">
            <p class="about_text">
              <b>Musiqa maktablari</b>, bolalar musiqa maktablari — boshlang'ich
              musiqa ta'limini beruvchi maxsus o'quv yurtlari. Musiqa
              maktablariga asosan umumta'lim maktablarida o'qiydigan iste'dodli
              bolalar va o'smirlar qabul qilinadi. Musiqa maktablarida musiqa
              cholgu asboblari (fortepiano, skripka, violonchel, truba, klarnet,
              akkordeon va boshqa, o'zbek xalq cholgularidan — doyra, qashqar
              rubobi, dutor, g'ijjak, chang, nay va boshqalar)da chalish
              o'rgatiladi; musiqa savodi va elementar musiqa nazariyasi,
              solfejio, musiqa adabiyoti, xor qo'shiqchiligi bo'yicha ta'lim
              beriladi.
            </p>
            <!-- <a href="#Ready_Training_page"
              >Biz bilan San'at sirlarini o'rganing!</a
            > -->
          </div>
        </div>
        <!-- About_content End -->
      </div>
    </section>
    <!-- About_djBeats End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style scoped>
.about_text {
  font-size: 20px !important;
}
</style>
