<template>
  <div>
    <!-- Our_Services_page Start -->
    <section id="Our_Services_page">
      <div class="container">
        <div class="row Services_title">
          <div class="col-12">
            <h1>Bizning <span>Yo'nalishlarimiz</span></h1>
            <p>Biz bilan muvaffaqqiyatga erishing.</p>
          </div>
        </div>
        <!-- Services_title End -->
        <div class="row Services_content">
          <!-- 1-row -->
          <div class="row justify-content-center ser_content">
            <div class="col-12 col-lg-6 ser_content_img">
              <div class="row justify-content-center py-3">
                <div class="col-10 col-sm-2 text-center d-block text-sm-start">
                  <img
                    src="/img/icon_01_Our_team.png"
                    alt="icon_01_Our_team"
                  />
                  
                </div>
                <div class="col-10 col-sm-10 text-center d-block text-sm-start">
                  <h5>Muvofiqlik</h5>
                  <p>
                    Adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 ser_content_img">
              <div class="row justify-content-center py-3">
                <div class="col-10 col-sm-2 text-center d-block text-sm-start">
                  <img
                    src="/img/icon_04_Our_team.png"
                    alt="icon_01_Our_team"
                  />
                </div>
                <div class="col-10 col-sm-10 text-center d-block text-sm-start">
                  <h5>Musiqa Dizayn</h5>
                  <p>
                    Adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 2-row -->
          <div class="row justify-content-center ser_content">
            <div class="col-12 col-lg-6 ser_content_img">
              <div class="row justify-content-center py-3">
                <div class="col-10 col-sm-2 text-center d-block text-sm-start">
                  <img
                    src="/IMG/icon_02_Our_team.png"
                    alt="icon_02_Our_team"
                    class=""
                  />
                </div>
                <div class="col-10 col-sm-10 text-center d-block text-sm-start">
                  <h5>O'zlashtirish</h5>
                  <p>
                    Adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.aliquam erat.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 ser_content_img">
              <div class="row justify-content-center py-3">
                <div class="col-10 col-sm-2 text-center d-block text-sm-start">
                  <img
                    src="/IMG/icon_05_Our_team.png"
                    alt="icon_01_Our_team"
                  />
                </div>
                <div class="col-10 col-sm-10 text-center d-block text-sm-start">
                  <h5>Ovoz yozish</h5>
                  <p>
                    Adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 3-row -->
          <div class="row justify-content-center ser_content">
            <div class="col-12 col-lg-6 ser_content_img">
              <div class="row justify-content-center py-3">
                <div class="col-10 col-sm-2 text-center d-block text-sm-start">
                  <img
                    src="/IMG/icon_03_Our_team.png"
                    alt="icon_03_Our_team"
                  />
                </div>
                <div class="col-10 col-sm-10 text-center d-block text-sm-start">
                  <h5>Ishlab chiqarish</h5>
                  <p>
                    Adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.aliquam erat
                    volutpat.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 ser_content_img">
              <div class="row justify-content-center py-3">
                <div class="col-10 col-sm-2 text-center d-block text-sm-start">
                  <img
                    src="/IMG/icon_06_Our_team.png"
                    alt="icon_06_Our_team"
                  />
                </div>
                <div class="col-10 col-sm-10 text-center d-block text-sm-start">
                  <h5>Fortepiano darslari</h5>
                  <p>
                    Adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Services_content End -->
      </div>
    </section>
    <!-- Our_Services_page End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style></style>
