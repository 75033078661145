<template>
  <div>
    <!-- Courses & Trainings Start -->
    <section id="Courses_Trainings_page">
      <div class="container">
        <div class="row Courses_title">
          <div class="col-12">
            <h1><span>Yangiliklar</span> va Tadbirlardan lavhalar</h1>
            <p>Bizni kuzatib boring</p>
          </div>
        </div>
        <!-- Sourses_title End -->
        <div class="row justify-content-center Courses_cards">
          <div class="col-11 col-sm-8 col-md-6 col-lg-4 p-0 py-2 py-md-0 cours_hover_card">
            <div class="card border-0">
              <img src="/img/course_01_bg.jpg" alt="course_01_bg" class="img-fluid"/>
              <div class="Cours_card_text">
                <h2>Ishlash DJ</h2>
                <p>
                  Some description goes here<br />
                  and some text loop
                </p>
                <h1>$ 399</h1>
                <span>2 month / 24 trainings</span>
                <a href="#Ready_Training_page" class="d-block"><span>Darsni boshlash</span></a>
              </div>
            </div>
            <!-- Card End -->
            <div class="card_cover"></div>
          </div>
          <div class="col-11 col-sm-8 col-md-6 col-lg-4 p-0 py-2 py-md-0 cours_hover_card">
            <div class="card border-0">
              <img src="/img/course_02_bg.jpg" alt="course_02_bg" class="img-fluid"/>
              <div class="Cours_card_text">
                <h2>Producer</h2>
                <p>
                  Some description goes here<br />
                  and some text loop
                </p>
                <h1>$ 499</h1>
                <span>3 oy / 36 trening</span>
                <a href="#Ready_Training_page" class="d-block"><span>Darsni boshlash</span></a>
              </div>
            </div>
            <div class="card_cover"></div>
          </div>
          <div class="col-11 col-sm-8 col-md-6 col-lg-4 p-0 py-2 py-md-0 cours_hover_card">
            <div class="card border-0">
              <img src="/img/course_03_bg.jpg" alt="course_03_bg" class="img-fluid"/>
              <div class="Cours_card_text">
                <h2>Performance DJ</h2>
                <p>
                  Some description goes here<br />
                  and some text loop
                </p>
                <h1>$ 399</h1>
                <span>2 month / 24 trainings</span>
                <a href="#Ready_Training_page" class="d-block"><span>Darsni boshlash</span></a>
              </div>
            </div>
            <div class="card_cover"></div>
          </div>
        </div>
        <div class="row justify-content-center pt-5">
          <div class="col-12 text-center pt-5">
            <p class="text-white fs-5">
              Agar sizga individual qo'shimcha darslar kerak bo'lsa, biz yordam
              beramiz.
              <a href="#Ready_Training_page" class="text-decoration-underline text-warning">
                Biz bilan bog'lanish
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Courses & Trainings End -->
  </div>
</template>

<!-- Script -->
<script>
export default {};
</script>

<!-- Style -->
<style></style>
